import type { NextPage } from 'next';
import { useMemo } from 'react';

import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { FILTERS_NAMES, MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { Layout as AppLayout } from 'app/Layout';
import { AmountFilter } from 'features/AmountFilter';
import { useIsPageWithCalculator } from 'features/Calculator';
import { BrandingHeaders } from 'widgets/BrandingHeaders';
import { CalculatorWithHeaders } from 'widgets/CalculatorWithHeaders';
import { FullDealEntryPoint } from 'widgets/FullDealEntryPoint';
import { useIsShowFullDealEntrypoint } from 'widgets/FullDealEntryPoint/lib';
import { ProductListFilterSortPanel } from 'widgets/ProductListFilterSortPanel';
import { SeoComponents } from 'widgets/SeoComponents';

import styles from './PodPtsPage.module.scss';

const pageConfig: IPageContextConfig = {
  listKey: ListKeys.ZAIMY_POD_PTS,
  source: 'search',
  sortPanelVisible: true,
  seoLinksVisible: true,
  extraFiltersVisible: true,
  cardTermIsVisible: true,
  withBanners: true,
  withInvitationBanner: true,
  withEmailBurner: true,
  withSelectionBanner: true,
  withCardProductLink: true,
};

export const PodPtsPage: NextPage = () => {
  const isMobile = useIsMobile();

  const { filter, seo, pathname } = useListPage(ListKeys.ZAIMY_POD_PTS);
  const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);

  useLoadRestOffers(ListKeys.ZAIMY_POD_PTS);
  useBestProducts();

  const isCalculatorVisible = useIsPageWithCalculator();
  const isShowFullDealEntrypoint = useIsShowFullDealEntrypoint();

  return (
    <AppLayout>
      <PageContext.Provider value={config}>
        <BrandingHeaders
          containerClassName={styles.page_header}
          afterHeaders={
            isShowFullDealEntrypoint ? (
              <>
                <FullDealEntryPoint heading={seo?.heading} />
                <ProductListFilterSortPanel
                  initialAmount={filter.filters[FILTERS_NAMES.AMOUNT]}
                  initialTerm={filter.filters[FILTERS_NAMES.TERM]}
                  sorting={filter.filters[FILTERS_NAMES.SORT]}
                  onChange={filter.setFilter}
                />
              </>
            ) : (
              <AmountFilter filters={filter} listKey={ListKeys.ZAIMY_POD_PTS} />
            )
          }
        />

        <ProductList className={styles.product_list} filter={filter} pathname={pathname} />

        {isCalculatorVisible && <CalculatorWithHeaders filters={filter.filters} />}

        {seo && (
          <SeoComponents
            seo={seo}
            breadcrumbs={seo.breadcrumbs}
            prefooter={seo.prefooter}
            showAnchorLinks={isShowFullDealEntrypoint}
          />
        )}
        <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
      </PageContext.Provider>
    </AppLayout>
  );
};

PodPtsPage.getInitialProps = async (ctx: Application.ReduxNextPageContext) => {
  await getPageListData(ctx, {
    key: ListKeys.ZAIMY_POD_PTS,
    defaultFiltersMode: MODES.ZAIMY_POD_PTS,
  });
};
